<template>
  <div>
    <b-modal
      id="parcelasList"
      ref="parcelasList"
      button-size="sm"
      no-close-on-backdrop
      ok-only
      :no-stacking="stacking"
      ok-title="Ok"
      size="lg"
      :no-enforce-focus="true"
    >
      <div slot="modal-title">
        <h4>{{ tipoParcelamento === 'pgfn' ? `${parcelamento.identificacao} - ${parcelamento.razaoSocial}` : 'Parcelas - ' + parcelamento.razaoSocial }}</h4>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão as últimas parcelas lidas pelo Monitor, você pode mandar as guias por email ou baixar.
        </fieldset>
      </div>
      <h5
        v-if="showParc"
        style=""
      >
        {{ tipoParcelamento === 'relp' ? 'Remanescentes' : 'Histórico' }} <span style="float: right;">{{ parcelamento.totalParcelas }}</span>
      </h5>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="parcelas"
            :somente-visualizacao="false"
            :customizar-botoes="true"
            :colunas="colunas"
            class="tabela-parcelas"
          >
            <template #visualizacao="{slotScope: {key, valor}}">
              <b-badge
                v-if="key === 'status'"
                :variant="valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')"
              >
                {{ valor }}
              </b-badge>
              <span
                v-else-if="key === 'competencia'"
                :style="validaPeriodo(valor)"
                class="ml-50"
              >
                {{ valor }}
              </span>
              <span
                v-else
                class="ml-50"
              >
                {{ valor }}
              </span>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <div v-if="item.status.indexOf('paga') < 0 && parcelamento.situacao != 'Encerrado por rescisão'">
                <button
                  v-b-tooltip.hover
                  title="Gerar Parcela"
                  class="btn btn-sm btn-none px-50 py-0"
                  @click.stop="visualizarGuia(item.id, item.status.indexOf('atraso') > 0)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-barcode"
                    class="text-primary"
                  />
                </button>

                <b-btn
                  v-b-tooltip.hover
                  title="Observação"
                  variant="none"
                  class="btn-none rounded-pill m-0 icone-acao p-0"
                  @click="$emit('openObservacoes', item)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-message"
                    :class="{ 'text-warning': item.temObservacoesNaoLidas, 'text-primary': !item.temObservacoesNaoLidas }"
                  />
                </b-btn>

                <button
                  v-b-tooltip.hover
                  title="Enviar por email"
                  class="btn btn-sm btn-none px-50 py-0"
                  @click="openEnvioEmail(item.id, item.status.indexOf('atraso') > 0)"
                >
                  <feather-icon
                    icon="SendIcon"
                    size="20"
                    class="text-primary"
                  />
                </button>
              </div>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
      <h5
        v-if="showParcEntrada"
        class="mt-2"
      >
        Entrada <span style="float: right;">{{ parcelamento.totalParcelasEntrada }}</span>
      </h5>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="parcelasEntrada"
            :somente-visualizacao="false"
            :customizar-botoes="true"
            :colunas="colunas"
            class="tabela-parcelas"
          >
            <template #visualizacao="{slotScope: {key, valor}}">
              <b-badge
                v-if="key === 'status'"
                :variant="valor.indexOf('atraso') > 0 ? 'light-danger' : (valor.indexOf('paga') > 0 ? 'light-success' : 'light-primary')"
              >
                {{ valor }}
              </b-badge>
              <span
                v-else
                class="ml-50"
              >
                {{ valor }}
              </span>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <div v-if="item.status.indexOf('paga') < 0">
                <button
                  v-b-tooltip.hover
                  title="Gerar Parcela"
                  class="btn btn-sm btn-none px-50 py-0"
                  @click.stop="visualizarGuia(item.id, item.status.indexOf('atraso') > 0)"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-barcode"
                    class="text-primary"
                  />
                </button>

                <b-btn
                  v-b-tooltip.hover
                  title="Observação"
                  variant="none"
                  class="btn-none rounded-pill m-0 icone-acao p-0"
                  @click="$emit('openObservacoes', item)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-message"
                    :class="{ 'text-warning': item.temObservacoesNaoLidas, 'text-primary': !item.temObservacoesNaoLidas }"
                  />
                </b-btn>

                <button
                  v-b-tooltip.hover
                  title="Enviar por email"
                  class="btn btn-sm btn-none px-50 py-0"
                  @click="openEnvioEmail(item.id, item.status.indexOf('atraso') > 0)"
                >
                  <feather-icon
                    icon="SendIcon"
                    size="20"
                    class="text-primary"
                  />
                </button>
              </div>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
</style>

<script>
import moment from 'moment'

export default {
  name: 'ParcelaView',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    parcelamento: {
      type: Object,
      required: true,
    },
    requestParcelas: {
      type: Function,
      required: true,
    },
    tipoParcelamento: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      stacking: true,
      mostrarArquivo: false,
      urlArquivo: '',
      parcelas: [],
      parcelasEntrada: [],
      colunas: [
        {
          key: 'competencia', label: 'Competência', tdClass: 'col-2',
        },
        {
          key: 'numeroParcela', label: 'Parcela', tdClass: 'col-2',
        },
        {
          key: 'status', label: 'Status',
        },
        {
          key: 'valor', label: 'Valor (R$)',
        }],
      isLoading: false,
      showParc: false,
      showParcEntrada: false,
      docSelecionado: null,
      temObservacaoNova: false,
      mostrarObservacoes: false,
    }
  },
  methods: {
    carregarParcelas() {
      if (this.requestParcelas) {
        this.requestParcelas(this.parcelamento.id)
          .then(payload => {
            this.parcelas = payload.data.filter(f => !f.entrada)
            this.parcelasEntrada = payload.data.filter(f => f.entrada)
            this.showParc = this.parcelas.length > 0
            this.showParcEntrada = this.parcelasEntrada.length > 0
          })
          .catch(err => {
            throw err
          })
      }
    },
    show() {
      this.carregarParcelas()
      this.$refs.parcelasList.show()
    },
    pararEspera() {
      this.isLoading = false
    },
    iniciarEspera() {
      this.isLoading = true
    },
    openEnvioEmail(id) {
      this.$emit('envioEmail', { id: this.parcelamento.id, parcelaId: id })
    },
    visualizarGuia(id, esperar) {
      if (esperar) {
        this.iniciarEspera()
      }
      this.$emit('mostrarGuia', { id: this.parcelamento.id, parcelaId: id })
    },
    hide() {
      this.$refs.parcelasList.hide()
    },
    validaPeriodo(valor) {
      const dataCompetencia = moment(valor, 'MM/YYYY')
      const dataAtual = moment()

      if (dataCompetencia.year() === dataAtual.year() && dataCompetencia.month() === dataAtual.month()) {
        return 'font-weight: bold'
      }
      return ''
    },
  },
}

</script>
